<template>
  <v-app-bar
    color="accent"
    style="border-radius: 0 0 20px 20px;"
  >
    <v-row class="mx-sm-10 mx-7">

      <v-col
        v-for="item in navItems"
        :key="item.label"
        cols="auto"
        class="py-4 px-sm-0"
      >
        <v-btn
          v-if="xs"
          color="sand"
          size="small"
          :to="item.link"
          :icon="item.icon"
        />
        <div v-else class="monkey-title">
          <NuxtLink
            class="text-sand text-decoration-none"
            :to="item.link"
          >{{ item.label }}</NuxtLink>
        </div>
      </v-col>

      <v-spacer />

      <v-col cols="auto" :class="{'pt-4': xs}">
        <v-img
          src="@/assets/logos/meili_logo_wide-nobg.png"
          height="2.5rem"
          width="7rem"
          class="cursor-pointer"
          @click="$router.replace('/')"
        />
      </v-col>

      <v-spacer />

      <v-col
        v-for="item in navIcons"
        :key="item.label"
        cols="auto"
        class="py-4 px-sm-0"
      >
        <v-badge
          v-if="cartStore.allItemsSize > 0"
          :content="cartStore.allItemsSize"
          :max="10"
          location="top end"
          color="primary"
        >
          <v-btn
            v-if="xs"
            color="sand"
            size="small"
            :to="item.link"
            :icon="item.icon"
          />
          <div v-else class="monkey-title">
            <NuxtLink
              class="text-sand text-decoration-none"
              :to="item.link"
            >{{ item.label }}</NuxtLink>
          </div>
        </v-badge>
        <template v-else>
          <v-btn
            v-if="xs"
            color="sand"
            size="small"
            :to="item.link"
            :icon="item.icon"
          />
          <div v-else class="monkey-title">
            <NuxtLink
              class="text-sand text-decoration-none"
              :to="item.link"
            >{{ item.label }}</NuxtLink>
          </div>
        </template>
      </v-col>

    </v-row>
  </v-app-bar>
</template>

<script setup>
import { useDisplay } from "vuetify";

const cartStore = useCartStore();

const cartCookie = useCookie("ifga.userCart");

if (cartCookie.value) cartStore.setCart(cartCookie.value);

cartStore.$subscribe((mutation, state) => {
  cartCookie.value = JSON.stringify(state.cartItems);
});

const { xs } = useDisplay();
</script>

<script>
export default {
  name: "ComponentNavigation",
  data: () => ({
    navItems: [
      {
        label: "Notre crème",
        link: "product",
        icon: "fa fa-store",
      },
    ],

    navIcons: [
      {
        label: "Mon panier",
        link: "cart",
        icon: "fa fa-basket-shopping",
      },
    ],
  }),
};
</script>

<style>
.monkey-title {
  font-family: "Happy Monkey", system-ui!important;
  font-weight: 400;
  font-style: normal;
  line-height: 2rem;
}
</style>
